import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/PublicSpeakingForm.scss";
import { Helmet } from "react-helmet";

export default function PublicSpeaking() {
const data = useStaticQuery(graphql`
query MyQuery379 {
    allContentfulHeroImage(filter: { name: { eq: "Public Speaking Image" } }) {
    edges {
        node {
        heroImage {
            altText
            image {
            file {
                url
            }
            id
            }
            name
        }
        }
    }
    }
}
`)
  const [occupation, setOccupation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [isEmailInputEnabled, setIsEmailInputEnabled] = useState(false);

  const handleOccupationChange = (e) => {
    setOccupation(e.target.value);
    setErrorMessage(''); // Clear error message when a radio button is selected
  };

  const handleEmailInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage(''); // Clear the error message when the user starts typing a different email
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('MERGE0');
    console.log("Selected Radio Button Value: ", selectedRadioValue);
    console.log("Email: ", email);
  
    const emailInput = document.querySelector('input[type="email"]');
    const enteredEmail = emailInput.value.trim().toLowerCase();
  
    const disallowedDomains = ['@gmail', '@yahoo', '@icloud', '@aol', '@mac', '@hotmail', '@outlook', '@proton', '@protonmail', '@zohocorp', '@gmx', '@yandex', '@hubspot', '@neo', '@thunder', '@degcos', '@me'];
    const disallowedPrefixes = ['info', 'contact']; // Disallowed prefixes
  
    const containsDisallowedDomain = disallowedDomains.some(domain => enteredEmail.includes(domain));
    const emailPrefix = enteredEmail.split('@')[0]; // Extract the part before '@'
    const containsDisallowedPrefix = disallowedPrefixes.some(prefix => emailPrefix === prefix);
  
    if (!occupation) {
      setErrorMessage('Please select an occupation');
      return;
    } else if (containsDisallowedDomain) {
      setErrorMessage('Please use your business or educational email');
      console.error('Disallowed email domain used:', enteredEmail);
      return;
    } else if (containsDisallowedPrefix) {
        setErrorMessage('Please use your business or educational email');
      console.error('Disallowed email prefix used:', enteredEmail);
      return;
    }
  
    // If validation passes, proceed with form submission
    setErrorMessage('');
    console.log('Form submitted successfully!');
    e.target.submit(); // Manually trigger form submission
  };
  
  
  
  return (
      <>
    <Helmet>
        <title>Luxury Keynote Speaker – Insiders Perspective</title>
        <meta name="description" content="Luxury Keynote Speaker –Give attendees an insiders perspective to luxury digital transformation. Inspire students exclusive industry insights and reports" />
        <meta name="keywords" content="Luxury Keynote Speaker, Luxury thought leadership" />
        <h1>Luxury Keynote Speaker – Demystifying Data and Luxury Digital Transformation</h1>
        <h2>Make an Enquiry</h2>
    </Helmet>
    <div className="contentFeatureSentimentTextPS">
    <p className="subtitleSentimentTextPS">Protecting Multi Million Dollar Investment in Luxury Resale Leader</p>
    <div className="contentContainerPS">
        <div className="imageContainerPS">
          <img className="PS"
            src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}/>
        </div>
        <div className="formPS">
            <form action="https://thefreshkid.us8.list-manage.com/subscribe/post" method="POST" target="_blank" onSubmit={handleSubmit}>
                <input type="hidden" name="u" value="8abd2b2ed117ea16fd4a66f6d" />
                <input type="hidden" name="id" value="287d0e6057" />
                <p className="formTitlePS">Luxury Keynote Speaker - Demystifying Luxury Digital Transformation</p>
                <ul class="formBodyPS">
                    <li>Provide Innovative Luxury Insights to your employees, students, investors, conference attendants and the C-Suite</li>
                    <li>Luxury’s Insiders stories and learnings on thought leadership and digital transformation</li>
                    <li>Highly visual and entertaining data visualization</li>
                </ul>
                <div className="imageContainerPS1">
                <img className="PS1"
                    src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}/>
                </div>
                <p className="formSubtitlePS">Make an Enquiry</p>
                <div className="title-section">
                <label className="TitleLabel" htmlFor="title">*Title: </label>
                <br></br>
                <label htmlFor="title">Select... </label>
                    <select name="MMERGE4" id="title" required>
                    <option value=""></option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    </select>
                </div>

                <div className="PSFirstName">
                    <label className="FirstNameLabel" htmlFor="firstname">*First Name:</label>
                    <input type="text" name="FNAME" required/>
                </div>
                <div className="PSLastName">
                    <label className="LastNameLabel" htmlFor="lastname">*Last Name:</label>
                    <input type="text" name="LNAME" required/>
                </div>
                <div className="PSBusinessEmail">
                    <label className="BusinessEmailLabel" htmlFor="businessemail">*Business or Educational Institute Email:</label>
                    <input type="email" name="MERGE0" onChange={handleEmailInputChange} required></input>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
                <div className="PSBusinessName">
                    <label className="BusinessNameLabel" htmlFor="businessname">*Business or Educational Institute Name:</label>
                    <input type="text" name="MMERGE5" required/>
                </div>

                <div className="PSOccupation">
                    <label className="OccuupationLabel" htmlFor="occupation"><em>*Please select your occupation</em></label>

                    <div className="radio-labels1">
                        <label classname= "radio1" htmlFor="luxuryeducationprofessional">
                            <input
                            type="radio"
                            name="MMERGE7"
                            id="luxuryeducationprofessional"
                            value="Luxury Education Professional"
                            onChange={handleOccupationChange}
                            required
                            />
                            <span class="spacers">&nbsp;&nbsp;</span>Luxury Education Professional
                        </label>
                        <label classname= "radio2" htmlFor="luxuryeventholder">
                            <input
                            type="radio"
                            name="MMERGE7"
                            id="luxuryeventholder"
                            value="Luxury Event Holder"
                            onChange={handleOccupationChange}
                            required
                            />
                            <span class="spacers">&nbsp;&nbsp;</span>Luxury Event Holder
                        </label>
                        <br></br>
                        <label classname= "radio3" htmlFor="other">
                            <input
                            type="radio"
                            name="MMERGE7"
                            id="other"
                            value="Other"
                            onChange={handleOccupationChange}
                            required
                            />
                            <span class="spacers">&nbsp;&nbsp;</span>Other
                        </label>
                    </div>
                </div>
                <div className="PSMessageBox">
                    <label className="MessageBoxLabel" htmlFor="message"><em>*Please share the details of your event:</em></label><br></br>
                    <textarea className="message-textarea" name="MMERGE6" id="message" cols="40" rows="2.5" required></textarea>
                </div>
                <div className="PSOther">
                    <label className="Other1Label" htmlFor="other"><em>*Required Information</em></label>
                    <br></br>
                    <label className="Other2Label" htmlFor="other">TFK will only use your personal information in order to respond to your inquiry</label>
                    <br></br>
                    <label className="Other3Label" htmlFor="other">Please consult our <a href="/PrivacyPolicy" class="privacy-link">Privacy Policy</a> for more information</label>
                </div>
                <div className="PSSubmit">
                    <input type="submit" value="Submit Enquiry" />
                </div>
            </form>
        </div>
    </div>
  </div>
  </>
  )
}


