import * as React from "react"

import Layout from "../components/Layout"
import HeroImageAbout from "../components/AboutPage/HeroImageAbout"
import PublicSpeakingForm from "../components/PublicSpeakingPage/PublicSpeakingForm"
import SentimentPageNewsletter from "../components/LuxurySentimentPage/SentimentPageNewsletter"


function publicspeakingPage({ data }) {
  return (
    <Layout>
      <PublicSpeakingForm />
    </Layout>
  )
}

export default publicspeakingPage